import {useStyleSheet} from "@gs-ux-uitoolkit-react/style";
import {dataChangeSummaryStyleSheet} from "../../../constants/StyleSheets";
import {
    getIssuerAndTickerDisplayName,
    getMALiveValue,
    getMAWipValue,
    getRatingLiveValueDisplayName,
    getRatingWipValueDisplayName,
    getTargetPriceLiveValue,
    getTargetPriceWipValue
} from "../../../services/utils/DataChangeService";
import {ListGroupItem, ListGroupItemHeading, ListGroupItemText} from "@gs-ux-uitoolkit-react/list-group";
import {Row} from "@gs-ux-uitoolkit-react/layout";
import {
    getConvictionListLiveValue,
    getConvictionListWipValue
} from "../../../services/utils/ConvictionListService";
import React from "react";
import {useQuery} from "@apollo/client";
import {GET_ADDITIONAL_CONTEXT} from "../../../constants/GraphqlCallDefinitions";
import parse from "html-react-parser";
import {AccordionPanel} from "@gs-ux-uitoolkit-react/accordion";

interface DataChangesSummaryProps {
    data: any
}

export const DataChangeSummary = ({data}: DataChangesSummaryProps) => {
    const styleClasses = useStyleSheet(dataChangeSummaryStyleSheet, null);
    const additionalContexts = useQuery(GET_ADDITIONAL_CONTEXT).data?.getAdditionalContext.additionalContexts;

    const getOtherChange = () => {
        return additionalContexts?.map((context:any) =>
        {
            if (context.nonQuantumChange == true) {
                return <AccordionPanel
                    header={"Other Change(s)"}
                >
                    <div>{parse(Object.values(context.context).toString() as string)} </div>
                </AccordionPanel>

            }
        });
    }

    const getRatingComparisonText = () => {
        const liveRating = getRatingLiveValueDisplayName(data);
        const wipRating = getRatingWipValueDisplayName(data);

        if (liveRating === wipRating) {
            return undefined;
        }

        const liveRatingDisplayValue = getDisplayValue(liveRating);
        const wipRatingDisplayValue = getDisplayValue(wipRating);

        return (
            <div>
                Rating Change from {liveRatingDisplayValue} to {wipRatingDisplayValue}
            </div>
        );
    }

    const getTargetPriceComparisonText = () => {
        const wipTargetPrice = getTargetPriceWipValue(data);
        const liveTargetPrice = getTargetPriceLiveValue(data);

        if (wipTargetPrice === liveTargetPrice) {
            return undefined
        }

        const liveTargetPriceDisplayValue = getDisplayValue(liveTargetPrice);
        const wipTargetPriceDisplayValue = getDisplayValue(wipTargetPrice);

        return (
            <div>
                Price Target Change from {liveTargetPriceDisplayValue} to {wipTargetPriceDisplayValue}
            </div>
        );
    }

    const getMAComparisonText = () => {
        const liveMA = getMALiveValue(data);
        const wipMA = getMAWipValue(data);

        if (liveMA === wipMA) {
            return undefined;
        }

        const liveMADisplayValue = getDisplayValue(liveMA);
        const wipMADisplayValue = getDisplayValue(wipMA);

        return (
            <div>
                M&A Change from {liveMADisplayValue} to {wipMADisplayValue}
            </div>
        );
    }

    function getDisplayValue(input: any) {
        return input || "--";
    }

    const getConvictionListComparisonText = () => {
        const liveConvictionList = getConvictionListLiveValue(data);
        const wipConvictionList = getConvictionListWipValue(data);

        if (liveConvictionList === wipConvictionList) {
            return undefined;
        }

        if(liveConvictionList) {
            return (
                <div>
                    Removed from Conviction List
                </div>
            );
        } else {
            return (
                <div>
                    Added to Conviction List
                </div>
            );
        }
    }

    return (
        <ListGroupItem>
            <ListGroupItemHeading>
                <Row className={styleClasses.summaryChangeTitle}>{getIssuerAndTickerDisplayName(data)}</Row>
            </ListGroupItemHeading>
            <ListGroupItemText className={styleClasses.summaryChangeBody}>
                {getOtherChange()}
                {getRatingComparisonText()}
                {getTargetPriceComparisonText()}
                {getMAComparisonText()}
                {getConvictionListComparisonText()}
            </ListGroupItemText>
        </ListGroupItem>
    );
}
