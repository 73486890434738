export type ChangeTypes =
    | 'COVERAGE_INITIATION'
    | 'COVERAGE_TERMINATION'
    | 'EPS'
    | 'LEGAL_RATING'
    | 'MERGERS_ACQUISITIONS_RANK'
    | 'RATING'
    | 'TARGET_PRICE'
    | 'TARGET_PRICE_PERFORMANCE'
    | 'TARGET_PRICE_PERIOD'
    | 'CONVICTION_LIST'
    | 'OTHER_CHANGE'
    | 'NOT_RATED_LEGAL_CHANGE';

export type FormattedChangeTypes =
    | 'Coverage Initiation'
    | 'Coverage Termination'
    | 'EPS'
    | 'Legal Rating'
    | 'M&A Rank'
    | 'Rating'
    | 'Target Price'
    | 'Target Price Performance'
    | 'Target Price Period'
    | 'Conviction List'
    | 'Other Change(s)'
    | 'Rating*';

export const ChangeTypesFormattingMap: {[key in ChangeTypes]: FormattedChangeTypes} = {
    'COVERAGE_INITIATION': 'Coverage Initiation',
    'COVERAGE_TERMINATION': 'Coverage Termination',
    'EPS': 'EPS',
    'LEGAL_RATING': 'Legal Rating',
    'MERGERS_ACQUISITIONS_RANK': 'M&A Rank',
    'RATING': 'Rating',
    'TARGET_PRICE': 'Target Price',
    'TARGET_PRICE_PERFORMANCE': 'Target Price Performance',
    'TARGET_PRICE_PERIOD': 'Target Price Period',
    'CONVICTION_LIST': 'Conviction List',
    'OTHER_CHANGE': 'Other Change(s)',
    'NOT_RATED_LEGAL_CHANGE': 'Rating*'
};